import {
  ANODE,
  CONSULTANCY,
  LITHIUM_ION_BATTERIES,
  NATURAL_GRAPHITE,
  SYNTHETIC_GRAPHITE,
  MANGANESE,
  PHOSPHATE,
  FLUORSPAR,
  RARE_EARTHS,
  SILICON_ANODE,
  SODIUM_ION_BATTERIES,
  SOLID_STATE,
  COBALT,
  NICKEL,
  LITHIUM,
  CATHODE,
  SOURCE,
  RECYCLING,
  PRICE_ASSESSMENTS,
  MARKET_ASSESSMENTS,
  FORECASTS,
  BATTERY_EMISSIONS_ANALYSER,
  PRICE_DASHBOARD,
  EXCEL_ADD_IN,
  BENCHMARK_API,
  BENCHMARK_APP,
  COPPER,
} from '~/constants/services/constants'

export const toolsSubMenu = [
  {
    name: PRICE_DASHBOARD.name,
    label: PRICE_DASHBOARD.name,
    to: `/${PRICE_DASHBOARD.slug}`,
    open: false,
    isPrivate: false,
  },
  {
    name: BATTERY_EMISSIONS_ANALYSER.name,
    label: BATTERY_EMISSIONS_ANALYSER.name,
    to: `/${BATTERY_EMISSIONS_ANALYSER.slug}`,
    open: false,
    isPrivate: false,
  },
  {
    name: BENCHMARK_API.name,
    label: BENCHMARK_API.name,
    to: `/${BENCHMARK_API.slug}`,
    open: false,
    isPrivate: false,
  },
  {
    name: EXCEL_ADD_IN.name,
    label: EXCEL_ADD_IN.name,
    to: `/${EXCEL_ADD_IN.slug}`,
    open: false,
    isPrivate: false,
  },
  {
    name: BENCHMARK_APP.name,
    label: BENCHMARK_APP.name,
    to: `/${BENCHMARK_APP.slug}`,
    open: false,
    isPrivate: false,
  },
]

export const ACCOUNT_MENU = [
  { name: 'My Account', href: '/account' },
  { name: 'Current Subscriptions', href: '/account/subscriptions' },
  { name: 'Change Password', href: '/account', menuStyle: 'border-none' },
]

const MARKETS = [
  {
    name: 'Upstream',
    href: '',
    parent: '',
    children: [
      {
        name: LITHIUM.name,
        href: `/${LITHIUM.slug}`,
      },
      {
        name: COPPER.name,
        href: `/${COPPER.slug}`,
      },
      {
        name: COBALT.name,
        href: `/${COBALT.slug}`,
      },
      {
        name: NICKEL.name,
        href: `/${NICKEL.slug}`,
      },
      {
        name: NATURAL_GRAPHITE.name,
        href: `/${NATURAL_GRAPHITE.slug}`,
      },
      {
        name: SYNTHETIC_GRAPHITE.name,
        href: `/${SYNTHETIC_GRAPHITE.slug}`,
      },
      {
        name: RARE_EARTHS.name,
        href: `/${RARE_EARTHS.slug}`,
      },
      {
        name: MANGANESE.name,
        href: `/${MANGANESE.slug}`,
      },
      {
        name: PHOSPHATE.name,
        href: `/${PHOSPHATE.slug}`,
      },
      {
        name: FLUORSPAR.name,
        href: `/${FLUORSPAR.slug}`,
      },
    ],
  },
  {
    name: 'Midstream',
    href: '',
    parent: '',
    children: [
      {
        name: ANODE.name,
        href: `/${ANODE.slug}`,
      },
      {
        name: CATHODE.name,
        href: `/${CATHODE.slug}`,
      },
      {
        name: SILICON_ANODE.name,
        href: `/${SILICON_ANODE.slug}`,
      },
    ],
  },
  {
    name: 'Downstream',
    href: '',
    parent: '',
    children: [
      {
        name: LITHIUM_ION_BATTERIES.name,
        href: `/${LITHIUM_ION_BATTERIES.slug}`,
      },
      {
        name: SODIUM_ION_BATTERIES.name,
        href: `/${SODIUM_ION_BATTERIES.slug}`,
      },
      {
        name: SOLID_STATE.name,
        href: `/${SOLID_STATE.slug}`,
      },
      {
        name: RECYCLING.name,
        href: `/${RECYCLING.slug}`,
      },
    ],
  },
]

export const DESKTOP_MENU = [
  {
    name: 'Markets',
    open: false,
    groups: [
      {
        name: 'Markets',
        href: '',
        hasSubMenu: true,
        children: MARKETS,
        orderMenuMobile: 1,
      },
    ],
  },
  {
    name: 'Services',
    open: false,
    groups: [
      {
        name: 'SUBSCRIPTIONS',
        href: '',
        children: [
          {
            name: 'Source',
            href: SOURCE.slug,
            target: '_blank',
            parent: '',
            icon: 'source',
          },
          {
            name: PRICE_ASSESSMENTS.name,
            href: `/${PRICE_ASSESSMENTS.slug}`,
            parent: '',
          },
          {
            name: MARKET_ASSESSMENTS.name,
            href: `/${MARKET_ASSESSMENTS.slug}`,
            parent: '',
          },
          {
            name: FORECASTS.name,
            href: `/${FORECASTS.slug}`,
            parent: '',
          },
          {
            name: 'Sustainability',
            href: `/sustainability`,
            parent: '',
          },
        ],
        orderMenuMobile: 1,
      },
      {
        name: 'CONSULTANCY',
        href: '',
        children: [
          {
            name: 'Bespoke Studies',
            href: `/${CONSULTANCY.slug}`,
            parent: '',
          },
          {
            name: 'Life Cycle Assessments Consultancy ',
            href: '/lca',
            parent: '',
          },
        ],
        orderMenuMobile: 2,
      },
    ],
  },
  {
    name: 'Tools',
    open: false,
    groups: [
      {
        name: 'Tools',
        href: '',
        children: [
          {
            name: PRICE_DASHBOARD.name,
            href: `/${PRICE_DASHBOARD.slug}`,
            parent: '',
          },
          {
            name: BATTERY_EMISSIONS_ANALYSER.name,
            href: `/${BATTERY_EMISSIONS_ANALYSER.slug}`,
            parent: '',
          },
          {
            name: BENCHMARK_API.name,
            href: `/${BENCHMARK_API.slug}`,
            parent: '',
          },
          {
            name: EXCEL_ADD_IN.name,
            href: `/${EXCEL_ADD_IN.slug}`,
            parent: '',
          },
          {
            name: BENCHMARK_APP.name,
            href: `/${BENCHMARK_APP.slug}`,
            parent: '',
          },
        ],
        orderMenuMobile: 1,
      },
    ],
  },
  {
    name: 'Events',
    open: false,
    groups: [],
    href: '/events',
  },
  {
    name: 'Newsletters',
    open: false,
    groups: [],
    href: '/newsletters',
  },
]

export const MOBILE_MENU = [
  {
    name: 'Markets',
    open: false,
    groups: MARKETS,
  },
  {
    name: 'Services',
    open: false,
    groups: [
      {
        name: 'Subscriptions',
        href: '',
        children: [
          {
            name: 'Source',
            href: SOURCE.slug,
            target: '_blank',
            parent: '',
            icon: 'SourceIcon',
          },
          {
            name: 'Price Assessments',
            href: `/${PRICE_ASSESSMENTS.slug}`,
            parent: '',
          },
          {
            name: 'Market Assessments',
            href: `/${MARKET_ASSESSMENTS.slug}`,
            parent: '',
          },
          {
            name: 'Forecasts',
            href: `/${FORECASTS.slug}`,
            parent: '',
          },
          {
            name: 'Sustainability',
            href: `/sustainability`,
            parent: '',
          },
        ],
        orderMenuMobile: 1,
      },
      {
        name: 'Consultancy',
        href: '',
        children: [
          {
            name: 'Bespoke Studies',
            href: `/${CONSULTANCY.slug}`,
            parent: '',
          },
          {
            name: 'Life Cycle Assessments Consultancy ',
            href: '/lca',
            parent: '',
          },
        ],
        orderMenuMobile: 2,
      },
    ],
  },
  {
    name: 'Tools',
    open: false,
    groups: [
      {
        name: 'Price Dashboard',
        href: '/price-dashboard',
      },
      {
        name: 'Battery Emissions Analyser',
        href: `/battery-emissions-analyser`,
      },
      {
        name: BENCHMARK_API.name,
        href: `/${BENCHMARK_API.slug}`,
      },
      {
        name: EXCEL_ADD_IN.name,
        href: `/${EXCEL_ADD_IN.slug}`,
      },
      {
        name: BENCHMARK_APP.name,
        href: `/${BENCHMARK_APP.slug}`,
      },
    ],
  },
  {
    name: 'Events',
    open: false,
    groups: [],
    href: '/events',
  },
  {
    name: 'Newsletters',
    open: false,
    groups: [],
    href: '/newsletters',
  },
]
